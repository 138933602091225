
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseReport from '@/components/reports-v2/BaseReport.vue';
import gdbx from '@/store/modules/gdbx';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import { roundToFixed } from '@/util/number';
import moment from 'moment';

@Component({
	components: { DataInfo },
})
export default class DailyNumberDisplayer extends Vue {
	@Prop({ default: false }) public readonly unfocused!: boolean;
	@Prop({ default: 0 }) public readonly todayAmount!: number;
	@Prop() public readonly yesterdayAmount!: number;
	@Prop({}) public readonly bracketAmount?: number;
	@Prop({ default: '' }) public readonly footer!: string;
	@Prop({ default: true }) public readonly isShowFooter!: boolean;

	public get numberText() {
		return BaseReport.shortenNumber(Math.ceil(this.todayAmount).toLocaleString());
	}

	public get amountDifference() {
		return Math.ceil(this.todayAmount - this.yesterdayAmount);
	}

	public get amountDifferenceText() {
		return this.amountDifference > 0
			? '+' + BaseReport.shortenNumber(this.amountDifference)
			: BaseReport.shortenNumber(this.amountDifference);
	}

	public get amountDifferenceMargin() {
		return roundToFixed(
			this.yesterdayAmount !== 0
				? ((this.todayAmount - this.yesterdayAmount) /
						Math.abs(this.yesterdayAmount)) *
						100
				: this.todayAmount,
		);
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}
}
