
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseFilter from './BaseFilter.vue';
import { ValueText } from '../../../../store/models.def';

interface CategoryData {
	value: string[];
	text: string;
	selected: boolean;
	indeterminate: boolean;
}

@Component({
	components: {},
})
export default class ListFilter extends BaseFilter {
	public get limitedOptions() {
		return this.filteredOptions.slice(0, this.optionsLimit);
	}
	public get limitedCategories() {
		return this.filteredCategories.slice(0, this.categoriesLimit);
	}

	public get filteredCategories(): CategoryData[] {
		if (!this.searchString || !this.searchable) {
			return this.categoryDatas;
		}
		const keywords = this.searchString
			.toLowerCase()
			.split(/,\s*/g)
			.map((s) => s.trim())
			.filter((s) => s);

		return this.categoryDatas.filter((data) => this.match(keywords, data.text));
	}
	public get filteredOptions(): ValueText[] {
		if (!this.searchString
            || !this.searchable
        ) {
			return this.options;
		}
		const keywords = this.searchString
			.toLowerCase()
			.split(/,\s*/g)
			.map((s) => s.trim())
			.filter((s) => s);
		this.optionsLimit = 10;
		this.categoriesLimit = 10;
		return this.options.filter(
			(data) =>
				this.match(keywords, data.text) || this.match(keywords, data.value),
		);
	}

	@Prop({ default: 'List Name' }) public filterName!: string;
	@Prop({ default: 'fa fa-list' }) public iconClass!: string;
	@Prop({ default: () => [] }) public options!: ValueText[];
	@Prop({ default: () => [] }) public categories!: ValueText[];
	@Prop({ default: () => [] }) public value!: string[];
	@Prop({ default: true }) public searchable!: boolean;
    @Prop({ default: false }) public activity?: boolean;

    public debounceTimer: any = null;
	public optionsLimit = 10;
	public categoriesLimit = 10;

	public selected: string[] = this.value;
	// public selectedCategories: string[] = this.value;
	public categoryDatas: CategoryData[] = [];
	// public selected: string[] = [];
	public allSelected = false;
	public indeterminate = false;
    public includeNonactive = true;

	public searchString: string = '';
	public onLoadMoreOptions() {
		this.optionsLimit += 10;
	}
	public onLoadMoreCategories() {
		this.categoriesLimit += 10;
	}

    @Watch('includeNonactive')
    public onIncludeNonactiveChange() {
        this.$emit('nonactive-toggle', this.includeNonactive);
    }

    @Watch('searchString')
    public onSearchStringChange() {
        // Use debounce to delay filtering
        this.debounce(() => {
            // Code to update limitedOptions
            this.optionsLimit = 10; // Reset options limit
            this.categoriesLimit = 10; // Reset categories limit
        }, 300); // 300ms delay
    }

	@Watch('categories', { immediate: true })
	public onCategoriesChange(newVal: ValueText[], oldVal: ValueText[]) {
		this.categoryDatas = newVal.map((vt) => ({
			value: vt.value,
			text: vt.text,
			selected: false,
			indeterminate: false,
		}));
		this.updateCategoryDatas();
	}

    public toggleInactive(checked: boolean) {
        this.selected = this.options.map((vt) => vt.value).sort();
    }

	public toggleAll(checked: boolean) {
		this.selected = (checked ? this.options.map((vt) => vt.value) : []).sort();
	}
	@Watch('value')
	public onValueChange(newVal: string[], oldVal: string[]) {
		this.selected = newVal;
	}
	@Watch('selected')
	public onSelectedChange(newVal: string[], oldVal: string[]) {
		if (newVal.length === 0) {
			this.indeterminate = false;
			this.allSelected = false;
			this.categoryDatas.forEach((data) => {
				data.indeterminate = false;
				data.selected = false;
			});
		} else if (newVal.length === this.options.length) {
			this.indeterminate = false;
			this.allSelected = true;
			this.categoryDatas.forEach((data) => {
				data.indeterminate = false;
				data.selected = true;
			});
		} else {
			this.indeterminate = true;
			this.allSelected = false;
			this.updateCategoryDatas();
		}
		if (this.selected !== this.value) {
			this.$emit('input', this.selected);
		}
	}

	public updateCategoryDatas() {
		const datas = this.categoryDatas;
		const clone = [...this.selected];
		for (const category of datas) {
			const found = matchCategory(category.value, clone);
			if (found === 0) {
				category.selected = false;
				category.indeterminate = false;
			} else if (found === category.value.length) {
				category.selected = true;
				category.indeterminate = false;
			} else {
				category.selected = false;
				category.indeterminate = true;
			}
		}

		function matchCategory(members: string[], selected: string[]): number {
			let found = 0;
			for (const member of members) {
				const i = selected.indexOf(member);
				if (i !== -1) {
					selected.splice(i, 1);
					found++;
				}
			}
			return found;
		}
	}

    // Other properties and methods remain unchanged...

    // Method to debounce function calls
    public debounce(func: () => void, wait: number) {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout(func, wait);
    }

	public match(keywords: string[], target: string) {
        const text = target.toString().toLowerCase();

        // If keywords is empty, no match should occur
        if (keywords.length === 0) {
            return false;
        }

        // Check if any keyword is present in the text
        for (const keyword of keywords) {
            if (text.indexOf(keyword) !== -1) {
                return true;
            }
        }

        // If no keyword matches, return false
        return false;
	}

	public selectCategory(data: CategoryData) {
		const members = data.value;
		if (data.selected) {
			this.selected = this.selected
				.filter((value) => !members.includes(value))
				.sort();
		} else {
			const set = new Set([...this.selected, ...members]);
			this.selected = Array.from(new Set(set)).sort();
		}
	}
}
