
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Panel from 'primevue/panel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

@Component({
	components: { Panel, DataTable, Column },
})
export default class TableCollapseToggle extends Vue {
	@Prop({ default: () => [] }) public readonly tableDatas!: any[];
	@Prop({ default: () => [] }) public readonly tableFields!: any[];
	@Prop({ default: 'Summary' }) public readonly buttonName!: string;
	@Prop() public sortBy?: string;
	@Prop({ default: true }) public sortDesc!: boolean;
	@Prop({ default: false }) public visible!: boolean;
	@Prop({ default: false }) public stickyHeader!: boolean | string;
    @Prop({default: true}) public toggleable?: boolean;

	public mySortBy: string = '';
	public mySortDesc: string = '';

	@Watch('sortBy', { immediate: true })
	public onSortByChange(newValue: string, oldValue: string) {
		this.mySortBy = newValue;
	}
	@Watch('sortDesc', { immediate: true })
	public onSortDescChange(newValue: string, oldValue: string) {
		this.mySortDesc = newValue;
	}

	public processHeaderName(name: string) {
		const newString = name.replaceAll('_', ' ');
		const finalString = newString
			.toLowerCase()
			.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
		return finalString;
	}

	public formatLocal(option: any, value: any) {
		if (option) {
			return option(value);
		}

		return value;
	}
}
