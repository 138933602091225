import * as echarts from 'echarts/core';
import { addComma } from '@/util/number';
import gdbx from '@/store/modules/gdbx';

export class DataViewFormatter {
	public static basic = (opt) => {
		const axisData = opt.xAxis[0].data;
		const series = opt.series;
		let tdHeaders = '<td></td>';
		series.forEach((item) => {
			const names = item.name || '';
			tdHeaders += '<td>' + names + '</td>';
		});
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr>' + tdHeaders + '</tr>';
		let tdBodys = '';
		for (let i = 0, l = axisData.length; i < l; i++) {
			for (const j of series) {
				const value = j.data[i] === '-' ? 0 : j.data[i] as number;
				tdBodys += '<td>' + DataViewFormatter.formatNumberIfDecimal(value) + '</td>';
			}
			table += '<tr><td style="padding: 0 10px">' + axisData[i] + '</td>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static salesTracker = (opt) => {
		const axisData = opt.xAxis[0].data;

		const series = opt.series;

		let tdHeaders = '<td></td>';
		series.forEach((item, index) => {
			if (index !== 4) {
				const names = item.name || '';
				tdHeaders += '<td>' + names + '</td>';
			}
		});
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr>' + tdHeaders + '</tr>';
		let tdBodys = '';
		for (let i = 0, l = axisData.length; i < l; i++) {
			for (const j of series) {
				if (j.name !== '') {
					const value = j.data[i] === '-' ? 0 : j.data[i] as number;
					tdBodys += '<td>' + DataViewFormatter.formatNumberIfDecimal(value) + '</td>';
				}
			}
			table += '<tr><td style="padding: 0 10px">' + axisData[i] + '</td>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static barChart = (opt) => {
		const axisData = opt.yAxis[0].data;
		const series = opt.series;
		let tdHeaders = '<td></td>';
		series.forEach((item) => {
			const names = item.name || '';
			tdHeaders += '<td>' + names + '</td>';
		});
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr>' + tdHeaders + '</tr>';
		let tdBodys = '';
		for (let i = 0, l = axisData.length; i < l; i++) {
			for (const j of series) {
				tdBodys += '<td>' + gdbx.currencySymbol + ' ' + DataViewFormatter.formatNumberIfDecimal(j.data[i].value) + '</td>';
			}
			table += '<tr><td style="padding: 0 10px">' + axisData[i] + '</td>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static horizontalBarChart = (opt) => {
		const axisData = opt.yAxis[0].data;
		const series = opt.series;
		let tdHeaders = '<td></td>';
		series.forEach((item) => {
			const names = item.name || '';
			tdHeaders += '<td>' + names + '</td>';
		});
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr>' + tdHeaders + '</tr>';
		let tdBodys = '';
		for (let i = 0, l = axisData.length; i < l; i++) {
			for (const j of series) {
				tdBodys += '<td>' + gdbx.currencySymbol + ' ' + DataViewFormatter.formatNumberIfDecimal(j.data[i]) + '</td>';
			}
			table += '<tr><td style="padding: 0 10px">' + axisData[i] + '</td>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static horizontalBarChartWithoutCurrency = (opt) => {
		const axisData = opt.yAxis[0].data;
		const series = opt.series;
		let tdHeaders = '<td></td>';
		series.forEach((item) => {
			const names = item.name || '';
			tdHeaders += '<td>' + names + '</td>';
		});
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr>' + tdHeaders + '</tr>';
		let tdBodys = '';
		for (let i = 0, l = axisData.length; i < l; i++) {
			for (const j of series) {
				tdBodys += '<td>' + ' ' + DataViewFormatter.formatNumberIfDecimal(j.data[i]) + '</td>';
			}
			table += '<tr><td style="padding: 0 10px">' + axisData[i] + '</td>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static horizontalBarChartWithPercentage = (opt) => {
		const axisData = opt.yAxis[0].data;
		const series = opt.series;
		let tdHeaders = '<td></td>';
		series.forEach((item) => {
			const names = item.name || '';
			tdHeaders += '<td>' + names + '</td>';
		});
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr>' + tdHeaders + '</tr>';
		let tdBodys = '';
		for (let i = 0, l = axisData.length; i < l; i++) {
			for (const j of series) {
				tdBodys += '<td>' + ' ' + DataViewFormatter.formatNumberIfDecimal(j.data[i]) + '%' + '</td>';
			}
			table += '<tr><td style="padding: 0 10px">' + axisData[i] + '</td>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static pieChart = (opt) => {
		const seriesData = opt.series[0].data;
		const series = opt.series;
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		for (let i = 0, l = seriesData.length; i < l; i++) {
			for (const j of series) {
				if (typeof (j.data[i]) === 'object') {
					tdBodys += '<td>' + j.data[i].name + '</td>';
					tdBodys += '<td>' + gdbx.currencySymbol + ' ' + DataViewFormatter.formatNumberIfDecimal(j.data[i].value) + '</td>';
				} else {
					tdBodys += '<td>' + j.data[i].name + '</td>';
					tdBodys += '<td>' + gdbx.currencySymbol + ' ' + DataViewFormatter.formatNumberIfDecimal(j.data[i]) + '</td>';
				}

			}
			table += '<tr>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static pieChartWithoutCurrency = (opt) => {
		const seriesData = opt.series[0].data;
		const series = opt.series;
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		for (let i = 0, l = seriesData.length; i < l; i++) {
			for (const j of series) {
				if (typeof (j.data[i]) === 'object') {
					tdBodys += '<td>' + j.data[i].name + '</td>';
					tdBodys += '<td>' + j.data[i].value + '</td>';
				} else {
					tdBodys += '<td>' + j.data[i].name + '</td>';
					tdBodys += '<td>' + j.data[i] + '</td>';
				}

			}
			table += '<tr>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static pieChartWithoutCurrencyDecimal = (opt) => {
		const seriesData = opt.series[0].data;
		const series = opt.series;
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		for (let i = 0, l = seriesData.length; i < l; i++) {
			for (const j of series) {
				if (typeof (j.data[i]) === 'object') {
					tdBodys += '<td>' + j.data[i].name + '</td>';
					tdBodys += '<td>' + j.data[i].value + '</td>';
				} else {
					tdBodys += '<td>' + j.data[i].name + '</td>';
					tdBodys += '<td>' + j.data[i] + '</td>';
				}

			}
			table += '<tr>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static nestedPieChart = (opt) => {
		const series = opt.series;
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		series.forEach((item, index) => {
			const datas = item.data;
			datas.forEach((data) => {
				tdBodys += '<td>' + data.name + '</td>';
				tdBodys += '<td>' + gdbx.currencySymbol + ' ' + DataViewFormatter.formatNumberIfDecimal(data.value) + '</td>';
				table += '<tr>' + tdBodys + '</tr>';
				tdBodys = '';
			});
		});

		table += '</tbody></table></div>';
		return table;

	}

	public static assestsLiabilities = (opt) => {
		const series = opt.series;
		// const xAxisData = opt.xAxis[0].data;
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		const amounts: any[] = [];
		const name: string[] = [];
		series.forEach((element, i) => {
			Object.entries(element).forEach((value, j) => {
				if (value[0] === 'name') {
					name.push(value[1] as string);
				}
				if (value[0] === 'data') {
					if (i < 15) {
						const array = value[1] as any[];
						amounts.push(array[i]);
					} else {
						const array = value[1] as any[];
						amounts.push(array[15]);
					}
				}
			});

		});
		for (let k = 0, l = name.length; k < l; k++) {
			tdBodys += '<td>' + name[k] + '</td>';
			tdBodys += '<td>' + DataViewFormatter.formatNumberIfDecimal(amounts[k]) + '</td>';
			table += '<tr>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;



	}

	public static GLProfitAndLoss = (opt) => {
		const series = opt.series;
		const xAxisData = opt.xAxis[0].data;
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		const arrayPart1: any[] = [];
		const arrayPart2: any[] = [];
		const arrayFactor: any[] = [];
		const finalArray: any[] = [];
		series.forEach((element, i) => {
			if (i === 1) {
				Object.entries(element).forEach((value, j) => {
					if (value[0] === 'data') {
						const amount = value[1] as any[];
						amount.forEach((element2, k) => {
							const result = Object.values(element2);
							const number = result[0];
							arrayPart1[k] = number;
						});

						// amount.map((number, index) => {
						// 	if (number !== '-') {
						// 		array[index] = number;
						// 	}
						// });
					}
				});
			}
			if (i === 2) {
				Object.entries(element).forEach((value, j) => {
					if (value[0] === 'data') {
						const amount = value[1] as any[];
						amount.forEach((element2, k) => {
							const result = Object.values(element2);
							const number = result[0];
							arrayPart2[k] = number;
						});

						// amount.map((number, index) => {
						// 	if (number !== '-') {
						// 		array[index] = number;
						// 	}
						// });
					}
				});
			}
			if (i === 3) {
				Object.entries(element).forEach((value, j) => {
					if (value[0] === 'data') {
						const amount = value[1] as any[];
						amount.forEach((element2, k) => {
							arrayFactor[k] = element2;
						});
					}
				});
			}
		});
		arrayPart1.map((part1value, i) => {
			const result = Math.abs(part1value) * arrayFactor[i];
			finalArray.push(result);
		});
		arrayPart2.map((value, i) => {
			if (value < 0) {
				const final = Math.abs(finalArray[i]) + Math.abs(value);
				finalArray[i] = final * arrayFactor[i];
			}
		});


		for (let k = 0, l = xAxisData.length; k < l; k++) {
			tdBodys += '<td>' + xAxisData[k] + '</td>';
			tdBodys += '<td>' + DataViewFormatter.formatNumberIfDecimal(finalArray[k]) + '</td>';
			table += '<tr>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static formatNumberIfDecimal(n: number, decimal: number = this._numDecimal()) {
		if (n) {
			return addComma(n, decimal, true);
		} else {
			return addComma(0, decimal, true);
		}
	}

	public static cashFlow = (opt) => {
		const axisData = opt.xAxis[0].data;
		const series = opt.series;
		let tdHeaders = '<td></td>';
		series.forEach((item) => {
			const names = item.name || '';
			tdHeaders += '<td>' + names + '</td>';
		});
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr>' + tdHeaders + '</tr>';
		let tdBodys = '';
		for (let i = 0, l = axisData.length; i < l; i++) {
			for (const j of series) {
				if (j.name === 'Net Cash Flow') {
					const datas = j.data;
					// tslint:disable:max-line-length
					tdBodys += '<td>' +  gdbx.currencySymbol + ' ' + DataViewFormatter.formatNumberIfDecimal(datas[i] !== undefined ? datas[i].value : 0) + '</td>';
				} else {
					tdBodys += '<td>' +  gdbx.currencySymbol + ' ' + DataViewFormatter.formatNumberIfDecimal(j.data[i]) + '</td>';
				}
			}
			table += '<tr><td style="padding: 0 10px">' + axisData[i] + '</td>' + tdBodys + '</tr>';
			tdBodys = '';
		}

		table += '</tbody></table></div>';
		return table;

	}

	public static treemap = (opt) => {
		const series = opt.series;

		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Group</th><th>Item</th><th>Value</th></tr>';
		let table2 = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		let isHaveChildren = false;
		series.forEach((item, index) => {
			const datas = item.data;
			datas.forEach((data) => {
				const childDatas = data.children as any[];
				if (childDatas && childDatas.length > 0) {
					isHaveChildren = true;
					childDatas.forEach((childItem) => {
						if (childItem.value !== 0) {
							tdBodys += '<td>' + data.name + '</td>';
							tdBodys += '<td>' + childItem.name + '</td>';
							tdBodys += '<td>' + gdbx.currencySymbol + ' '
								+ DataViewFormatter.formatNumberIfDecimal(childItem.value) + '</td>';
							table += '<tr>' + tdBodys + '</tr>';
							tdBodys = '';
						}
					});
				} else {
					tdBodys += '<td>' + data.name + '</td>';
					tdBodys += '<td>' + gdbx.currencySymbol + ' '
						+ DataViewFormatter.formatNumberIfDecimal(data.value) + '</td>';
					table2 += '<tr>' + tdBodys + '</tr>';
					tdBodys = '';
				}
			});
		});

		table += '</tbody></table></div>';
		table2 += '</tbody></table></div>';
		return isHaveChildren ? table : table2;
	}

	public static treemapWithoutCurrency = (opt) => {
		const series = opt.series;

		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Group</th><th>Item</th><th>Value</th></tr>';
		let table2 = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		let isHaveChildren = false;
		series.forEach((item, index) => {
			const datas = item.data;
			datas.forEach((data) => {
				const childDatas = data.children as any[];
				if (childDatas && childDatas.length > 0) {
					isHaveChildren = true;
					childDatas.forEach((childItem) => {
						if (childItem.value !== 0) {
							tdBodys += '<td>' + data.name + '</td>';
							tdBodys += '<td>' + childItem.name + '</td>';
							tdBodys += '<td>' + DataViewFormatter.formatNumberIfDecimal(childItem.value) + '</td>';
							table += '<tr>' + tdBodys + '</tr>';
							tdBodys = '';
						}
					});
				} else {
					tdBodys += '<td>' + data.name + '</td>';
					tdBodys += '<td>' + DataViewFormatter.formatNumberIfDecimal(data.value) + '</td>';
					table2 += '<tr>' + tdBodys + '</tr>';
					tdBodys = '';
				}
			});
		});

		table += '</tbody></table></div>';
		table2 += '</tbody></table></div>';
		return isHaveChildren ? table : table2;
	}

	public static treemapWithoutCurrency2 = (opt) => {
		const series = opt.series;
		let table = '<div contenteditable="true"><table class="table table-bordered table-striped table-hover" style="text-align:center;"><tbody><tr><th>Item</th><th>Value</th></tr>';
		let tdBodys = '';
		series.forEach((item, index) => {
			const datas = item.data;
			datas.forEach((data) => {
				tdBodys += '<td>' + data.name + '</td>';
				tdBodys += '<td>' + ' ' + DataViewFormatter.formatNumberIfDecimal(data.value) + '</td>';
				table += '<tr>' + tdBodys + '</tr>';
				tdBodys = '';
			});
		});

		table += '</tbody></table></div>';
		return table;
	}

	private static _numDecimal() {
		return gdbx.numDecimal;
	}

}
