
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import settingx from '@/store/modules/settingx';
import Checkbox from 'primevue/checkbox';

@Component({
	components: { Checkbox },
})
export default class ShowProfitToggle extends Vue {
	@Prop({ default: 'Estimate Profit' }) public readonly checkBoxName!: string;

	public localShowProfit: boolean = this.globalShowProfit;

	public get globalShowProfit() {
		return settingx.now.showProfit;
	}

	@Watch('localShowProfit')
	public onLocalShowProfitChanged(newVal) {
		settingx.updateSettings({ showProfit: newVal });
	}

	@Watch('globalShowProfit')
	public onGlobalShowProfitChanged(newVal) {
		this.localShowProfit = newVal;
	}
}
