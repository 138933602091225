
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseReport from './reports-v2/BaseReport.vue';
import moment from 'moment';

@Component({
	components: {},
})
export default class LastSync extends BaseReport {
  public get lastLoaded() {
    return (moment(Date.now()).format('DD MMM YYYY hh:mm A'));
  }
}
