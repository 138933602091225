
import {Component} from 'vue-property-decorator';
import * as echarts from 'echarts';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import gdbx from '@/store/modules/gdbx';
import {nestedGroupBy} from '@/util/nestedGroupBy';
import moment from 'moment';

@Component({
  components: {
    StackedBarChartFilterable,
    TableFilterable,
  },
})

export default class InstafreshDashboard extends FilterWidget {

  public get exportFileName() {
    const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
    return 'Daily Sales' + '_' + 'As Of ' + formattedDate;
  }

  public currentTableItems = [];

  public get currencySymbol() {
    return gdbx.currencySymbol;
  }

  public get dateFormatted() {
    return [this.selectedAsOfDate];
  }

  public get expensiveHook() {
    const { selectedAsOfDate, selectedAgents, selectedSuppliers } = this;
    return JSON.stringify([
      selectedAsOfDate,
      selectedAgents,
      selectedSuppliers,
    ]);
  }

  public tableItems: any = [];

  public newTableFields: any [] = [
    {
      key: 'date',
      sortable: true,
      stickyColumn: true,
    }, {
      key: 'doc_type',
      sortable: true,
      stickyColumn: true,
    }, {
      key: 'amount',
      sortable: true,
      stickyColumn: true,
    }, {
      key: 'store_location',
      sortable: true,
      stickyColumn: true,
    },
  ];

  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      > = ['suppliers', 'agents', 'dateAsOf'];

  public result: number[] = [];
  public xAxisData: string[] = [];
  public series: echarts.ECharts[] | any = [];

  public get filterTable() {
    return this.currentTableItems = this.tableItems.filter((a) => moment(a.docDate)
        .format('DD MM YY') === moment(this.selectedAsOfDate)
        .format('DD MM YY'))
        .map((a) => {
          return {
            date: moment(a.docDate).format('DD MMM YYYY'),
            doc_type: a.docType,
            amount: this.thousandFormatter(a.localDocAmount),
            store_location: a.storeLocation,
          };
        });
  }

  public async expensiveCalc() {
    const daily = FilteredDatabase.ref('diyDailyProjectSales');

    const cards = await (await daily.get()).getCards();

    const grouped = nestedGroupBy(cards.filter((a) => moment(a.docDate)
        .format('DD MM YY') === moment(this.selectedAsOfDate)
        .format('DD MM YY')), ['storeLocation']);

    this.xAxisData = Object.keys(grouped);

    this.result = [];

    for (const location of Object.keys(grouped)) {
      this.result.push(grouped[location].reduce((a, b) => a + b.localDocAmount, 0));
    }

    this.series = [
      {
        name: 'Total',
        data: this.result,
        type: 'bar',
        barWidth: '40%',
      },
    ];

    this.saveHistory('series', 'xAxisData');
  }

  public thousandFormatter(x) {
    return gdbx.currencySymbol + ' ' + Math.ceil(x).toLocaleString('en-US');
  }

  public async mounted() {
    const daily = FilteredDatabase.ref('diyDailyProjectSales');

    this.tableItems = await (await daily.get()).getCards();
  }

}
