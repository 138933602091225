import * as echarts from 'echarts/core';
export class DataZoomList {
	public static xAxis(type: 'slider' | 'inside' = 'inside', length: number = 5, startValue: number = 0) {
		return {
			type,
			xAxisIndex: [0],
			filterMode: 'filter',
			startValue,
			endValue: startValue + length,
			handleSize: '150%',
			realtime: false,
		};
	}

	// public static GLProfitAndLoss(type: 'slider' | 'inside' = 'inside', length: number = 7, startValue: number = 0) {
	// 	return {
	// 		type,
	// 		xAxisIndex: [0],
	// 		filterMode: 'filter',
	// 		startValue,
	// 		endValue: startValue + length,
	// 	};
	// }
}
