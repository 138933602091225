
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts/core';
import {
	TooltipComponent,
	TooltipComponentOption,
	LegendComponent,
	LegendComponentOption,
} from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { GobiColor } from '@/helpers/color';
import resize from 'vue-resize-directive';
import uuidv1 from 'uuid/v1';
import { DataViewFormatter } from './helpers/dataViewFormatter';
import { TooltipFormatter } from './helpers/tooltipFormatter';

echarts.use([
	TooltipComponent,
	LegendComponent,
	PieChart,
	CanvasRenderer,
	LabelLayout,
]);

type EChartsOption = echarts.ComposeOption<
	TooltipComponentOption | LegendComponentOption | PieSeriesOption
>;

@Component({
	directives: {
		resize,
	},
})
export default class BasicPieChart extends Vue {
	@Prop({ default: () => [] })
	public readonly series!: EChartsOption;
	@Prop({
		default: () => [
			GobiColor.COLOR1,
			GobiColor.COLOR2,
			GobiColor.COLOR3,
			GobiColor.COLOR4,
			GobiColor.COLOR5,
			GobiColor.COLOR6,
		],
	})
	public readonly color!: string[];
	@Prop({}) public readonly legends?: echarts.EChartsCoreOption;
	@Prop({ default: 'Title' }) public readonly title!: string;
	@Prop({ default: () => DataViewFormatter.pieChart })
	public readonly dataViewFormatter!: any;
	@Prop({ default: () => TooltipFormatter.pieChart })
	public readonly tooltipFormatter!: any;

	public mainChart: echarts.ECharts | null = null;
	public elId: string = '';

	public created() {
		this.elId = uuidv1();
	}

	public mounted() {
		this.initChart();
	}

	public initChart() {
		const myChart: any = document.getElementById(this.elId);

		const mainChart = (this.mainChart = echarts.init(myChart));
		this.updateChart();
	}

	public get updateLegend() {
		if (!this.legends) {
			return this.legends;
		}
	}

	@Watch('series')
	public onSeriesChange(newVal, oldVal) {
		this.updateChart();
	}

	public updateChart() {
		if (!this.mainChart) {
			return;
		}
		const option: echarts.EChartsCoreOption = {
			color: this.color,
			tooltip: {
				trigger: 'item',
				// formatter: '{b} : ' + this.currencySymbol + ' ' + '{c}' + ' ({d}%)',
				formatter: this.tooltipFormatter,
			},
			legend: {
				type: 'scroll',
				bottom: 'bottom',
				orient: 'horizontal',
				data: this.legends,
			},
			toolbox: {
				feature: {
					saveAsImage: {
						name: this.title,
						title: 'Save as image',
						iconStyle: {
							borderColor: GobiColor.COLOR1,
						},
					},
					dataView: {
						show: true,
						readOnly: true,
						title: 'Chart data',
						lang: ['', 'Close', ''],
						iconStyle: {
							borderColor: GobiColor.COLOR1,
						},
						optionToContent: this.dataViewFormatter,
					},
				},
			},
			series: this.series,
		};

		this.mainChart.setOption(option, true);
	}

	public onResize() {
		if (this.mainChart) {
			this.mainChart.resize();
		}
	}
}
