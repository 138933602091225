
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts/core';
import {
	TitleComponent,
	TitleComponentOption,
	ToolboxComponent,
	ToolboxComponentOption,
	TooltipComponent,
	TooltipComponentOption,
	GridComponent,
	GridComponentOption,
	LegendComponent,
	LegendComponentOption,
	DataZoomSliderComponent,
	DataZoomComponentOption,
} from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { GobiColor } from '@/helpers/color';
import resize from 'vue-resize-directive';
import uuidv1 from 'uuid/v1';
import { TooltipFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tooltipFormatter';
import { DataZoomList } from '@/components/reports-v2/components/elements/charts/helpers/dataZoom';
import { DataViewFormatter } from './helpers/dataViewFormatter';
import gdbx from '@/store/modules/gdbx';

echarts.use([
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GridComponent,
	LegendComponent,
	LineChart,
	CanvasRenderer,
	UniversalTransition,
	DataZoomSliderComponent,
]);

type EChartsOption = echarts.ComposeOption<
	| TitleComponentOption
	| ToolboxComponentOption
	| TooltipComponentOption
	| GridComponentOption
	| LegendComponentOption
	| LineSeriesOption
	| DataZoomComponentOption
>;

@Component({
	directives: {
		resize,
	},
})
export default class BaseLineChart extends Vue {
	// x-Axis
	@Prop({ default: () => [] }) public readonly xAxisData!: string[];
	@Prop({ default: '' }) public readonly xAxisLabel!: string;
	// y-Axis
	@Prop({ default: gdbx.currencySymbol }) public readonly yAxisLabel!: string;
	// Formatter
	@Prop({ default: () => TooltipFormatter.lineChart })
	public readonly formatter!: string;
	@Prop({ default: () => DataViewFormatter.basic })
	public readonly dataViewFormatter!: any;
	@Prop({ default: () => DataZoomList.xAxis('slider', 5, 0) })
	public readonly dataZoom!: echarts.EChartsCoreOption;
	// Other
	@Prop({}) public readonly legends!: EChartsOption;
	@Prop({ default: 'Title' }) public readonly title!: string;
	@Prop({
		default: () => [
			GobiColor.COLOR1,
			GobiColor.COLOR2,
			GobiColor.COLOR3,
			GobiColor.COLOR4,
			GobiColor.COLOR5,
			GobiColor.COLOR6,
		],
	})
	public readonly color!: string[];
	@Prop({ default: () => [] })
	public readonly series!: EChartsOption[];
	@Prop({ default: 'auto' })
	public readonly chartHeight!: number;

	public mainChart: echarts.ECharts | null = null;
	public elId: string = '';

	@Watch('xAxisData')
	public onXAxisDataChange(newVal, oldVal) {
		this.updateChart();
	}

	@Watch('series')
	public onSeriesChange(newVal, oldVal) {
		this.updateChart();
	}

	public created() {
		this.elId = uuidv1();
	}

	public mounted() {
		this.initChart();
	}

	public initChart() {
		const myChart: any = document.getElementById(this.elId);

		const mainChart = (this.mainChart = echarts.init(
			myChart,
			{},
			{ height: this.chartHeight },
		));

		this.updateChart();
	}

	public updateChart() {
		if (!this.mainChart) {
			return;
		}
		const option: echarts.EChartsCoreOption = {
			darkMode: 'auto',
			color: this.color,
			tooltip: {
				trigger: 'axis',
				formatter: this.formatter,
			},
			legend: {
				type: 'scroll',
				selectedMode: true,
				data: this.legends,
				padding: [0, 180, 0, 0],
			},
			toolbox: {
				feature: {
					saveAsImage: {
						name: this.title,
						title: 'Save as image',
						iconStyle: {
							borderColor: GobiColor.COLOR1,
						},
					},
					dataView: {
						show: true,
						readOnly: true,
						title: 'Chart data',
						lang: ['', 'Close', ''],
						iconStyle: {
							borderColor: GobiColor.COLOR1,
						},
						optionToContent: this.dataViewFormatter,
					},
				},
			},
			grid: {
				top: this.legends && this.legends.length === 0 ? '10%' : '15%',
				left: '3%',
				right: this.xAxisLabel ? '7%' : '5%',
				bottom: this.dataZoom && this.dataZoom.type === 'slider' ? '20%' : '0%',
				containLabel: true,
			},
			dataZoom: this.dataZoom ? [this.dataZoom] : undefined,
			xAxis: {
				type: 'category',
				boundaryGap: false,
				data: this.xAxisData,
				axisLabel: {
					fontSize: 10,
				},
				name: this.xAxisLabel,
				nameTextStyle: {
					fontWeight: 'bold',
				},
			},
			yAxis: {
				name: this.yAxisLabel,
				nameTextStyle: {
					fontWeight: 'bold',
				},
				min: 0,
				type: 'value',
			},
			stateAnimation: {
				duration: 300,
				easing: 'cubicOut',
			},
			animation: true,
			animationDuration: 1000,
			animationDurationUpdate: 500,
			animationEasing: 'cubicInOut',
			animationEasingUpdate: 'cubicInOut',
			animationThreshold: 2000,
			progressiveThreshold: 3000,
			progressive: 400,
			hoverLayerThreshold: 3000,
			series: this.series,
		};
		this.mainChart.setOption(option, true);
	}

	public onResize() {
		if (this.mainChart) {
			this.mainChart.resize();
		}
	}
}
