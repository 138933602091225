
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {DashboardApArDue, PermissionsGroup} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';

@Component({
	components: {
		TableFilterable,
	},
})
export default class TodayCustomerDueTable extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['dashboard'];
    }

    public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Sales Agent Ranking' + '_' + formattedDate;
	}

	public get dateFormatted() {
		return this.selectedAsOfDate;
	}

	public get expensiveHook() {
		const { selectedAsOfDate } = this;
		return JSON.stringify([selectedAsOfDate]);
	}

	public async expensiveCalc() {
		const mmtToday = moment(this.selectedAsOfDate).valueOf();

		const ref = FilteredDatabase.ref('dashboardApArDue')
			.includes('docType', ['IV'])
			.dateAsOf(mmtToday, 'dueDate');

		const refHead = await ref.get();
		const cards: DashboardApArDue[] = await refHead.getCards();

		this.tableFields = [
      { key: 'age', sortable: true, stickyColumn: true },
      { key: 'customer', sortable: true },
      {
        key: 'due_amount',
        sortable: true,
        formatter: TableItemFormatter.currency,
      },
      { key: 'sales_agent', sortable: true },
      { key: 'doc_date', sortable: true },
      { key: 'due_date', sortable: true },
			{ key: 'doc_no', sortable: true, stickyColumn: true },
		];

		this.tableItems = cards.map((item) => ({
			doc_no: item.docNo,
			customer: gdbx.customerNames[item.code],
			sales_agent: gdbx.agentNames[item.agent],
			due_amount: item.amount,
      doc_date: moment(item.date).format('DD/MM/yy'),
      due_date: moment(item.dueDate).format('DD/MM/yy'),
      age: Math.ceil(moment.duration(mmtToday - item.dueDate).asDays()),
		}));

		this.saveHistory('tableItems', 'tableFields');
	}
}
