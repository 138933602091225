
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import settingx from '@/store/modules/settingx';
import Checkbox from 'primevue/checkbox';

@Component({
	components: { Checkbox },
})
export default class IncludeTaxToggle extends Vue {
	public localIncludeTax: boolean = this.globalIncludeTax;

	public get globalIncludeTax() {
		return settingx.now.includeTax;
	}

	@Watch('localIncludeTax')
	public onToIsIncludeTaxChanged(newVal) {
		settingx.updateSettings({ includeTax: newVal });
	}

	@Watch('globalIncludeTax')
	public onGlobalIncludeTaxChanged(newVal) {
		this.localIncludeTax = newVal;
	}
}
