
import * as echarts from 'echarts/core';
import { TooltipComponent, TooltipComponentOption } from 'echarts/components';
import { addComma } from '@/util/number';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

echarts.use([
	TooltipComponent,
]);

type EChartsOption = echarts.ComposeOption<
	TooltipComponentOption
>;
export class TooltipFormatter {
	public static basic = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let rez = params[0].axisValue + '<br />';
		let count = 0;
		params.forEach((item) => {
			const value = item.value === '-' as any ? 0 : item.value as number;
			const xx =
				colorSpan(item.color) +
				' ' +
				item.seriesName +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		return rez;
	}
	public static assetsLiabilities = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		let rez = '';
		let count = 0;
		const params = param_s as EChartsOption[];
		params.forEach((item) => {
			const value = item.value as number;
			if (Math.round(value) === 0) {
				return;
			}
			const xx =
				colorSpan(item.color) +
				' ' +
				item.seriesName +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		if (count === 0) {
			let name = params[0].name;
			if (name === 'EQUITIES') {
				name = 'EQUITY';
			}
			const item = params.find(
				(param) => param.seriesName === name,
			);
			if (item) {
				return colorSpan(item.color) + ' ' + item.name + ': 0';
			}
		}
		return rez;
	}
	public static QuickRatio = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		let rez = '';
		let count = 0;
		const params = param_s as EChartsOption[];
		params.forEach((item) => {
			const value = item.value as number;
			if (Math.round(value) === 0) {
				return;
			}
			const xx =
				colorSpan(item.color) +
				' ' +
				item.seriesName +
				': ' +
				gdbx.currencySymbol + ' ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		return rez;
	}
	public static assetsLiabilities2 = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		let rez = '';
		let count = 0;
		const params = param_s as EChartsOption[];
		params.forEach((item) => {
			const value = item.value as number;
			if (value === 0) {
				return;
			}
			const xx =
				colorSpan(item.color) +
				' ' +
				item.seriesName +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		if (count === 0) {
			let name = params[0].name;
			if (name === 'EQUITIES') {
				name = 'EQUITY';
			}
			const item = params.find(
				(param) => param.seriesName === name,
			);
			if (item) {
				return colorSpan(item.color) + ' ' + item.name + ': 0';
			}
		}
		return rez;
	}
	public static lineChart = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		let params = param_s as EChartsOption[];
		let rez = params[0].axisValue + '<br />';
		let count = 0;
		params = params.filter((item) => item.value !== 0);
		params.sort((a, b) => (b.value as number) - (a.value as number));
		params.forEach((item) => {
			// console.log(item); //quite useful for debug
			const value = item.value as number;
			const xx =
				colorSpan(item.color) +
				' ' +
				item.seriesName +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		return rez;
	}
	public static pieChart = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let name;
		let value;
		let color;
		const keys = Object.keys(params);
		const values = Object.values(params);

		keys.forEach((key, index) => {
			if (key === 'name') {
				name = values[index];
			} else if (key === 'value') {
				value = values[index];
			} else if (key === 'color') {
				color = values[index];
			}
		});
		return colorSpan(color) + ' ' + name + ' : ' + gdbx.currencySymbol +
			' ' + TooltipFormatter.formatNumberIfDecimal(value);
	}
	public static pieChartNoCurrency = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let name;
		let value;
		let color;
		const keys = Object.keys(params);
		const values = Object.values(params);

		keys.forEach((key, index) => {
			if (key === 'name') {
				name = values[index];
			} else if (key === 'value') {
				value = values[index];
			} else if (key === 'color') {
				color = values[index];
			}
		});
		return colorSpan(color) + ' ' + name + ' : ' + TooltipFormatter.formatNumberIfDecimal(value);
	}
	public static pieChartNoCurrencyDecimal = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let name;
		let value;
		let color;
		const keys = Object.keys(params);
		const values = Object.values(params);

		keys.forEach((key, index) => {
			if (key === 'name') {
				name = values[index];
			} else if (key === 'value') {
				value = values[index];
			} else if (key === 'color') {
				color = values[index];
			}
		});
		return colorSpan(color) + ' ' + name + ' : ' + value;
	}
	public static lineChartForTotal = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let rez = params[0].axisValue + '<br />';

		rez += colorSpan(GobiColor.PURPLE) +
			' Total: ' +
			TooltipFormatter.formatNumberIfDecimal(params.reduce((sum, item) =>
				sum + (item.value as number), 0));

		let count = 1;
		params.sort((a, b) => (b.value as number) - (a.value as number));
		params.forEach((item) => {
			// console.log(item); //quite useful for debug
			const value = item.value as number;
			const xx =
				colorSpan(item.color) +
				' ' +
				item.seriesName +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		return rez;
	}
	public static profitMargin = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let rez = params[0].axisValue + '<br />';
		let count = 0;
		const order = ['Total Sales', 'Cost', 'Profit', 'Profit Margin (%)'];
		params.sort((a, b) => {
			const ai = order.indexOf(a.seriesName as any);
			const bi = order.indexOf(b.seriesName as any);
			return ai - bi;
		});
		params.forEach((item) => {
			// console.log(item); //quite useful for debug
			const value = item.value as number;
			const xx =
				colorSpan(item.color) +
				' ' +
				item.seriesName +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		return rez;
	}
	public static converted = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let rez = params[0].axisValue + '<br />';
		let count = 0;
		const a = params[0];
		const b = params[1];
		params[0] = b;
		params[1] = a;

		params.forEach((item) => {
			// console.log(item); //quite useful for debug
			const value = item.value as number;
			const xx =
				colorSpan(item.color) +
				' ' +
				item.seriesName +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		return rez;
	}
	public static GLProfitAndLoss = (param_s) => {
		const params = param_s as EChartsOption[];
		const tar1 = params[1].value as number;
		const tar2 = params[2].value as number;
		const factor = params[3].value as number;
		const total = Math.abs(tar1) + Math.abs(tar2 as number);
		return params[1].name + ' : ' + TooltipFormatter.formatNumberIfDecimal(total * factor);
	}

	public static AccountBalance = (param_s) => {
		const params = param_s as EChartsOption[];
		const tar0 = params[0].value as number;
		const tar1 = params[1].value as number;
		const tar2 = params[2].value as number;
		const total = Math.abs(tar0 as number) + Math.abs(tar1) + Math.abs(tar2 as number);
		return params[0].name + ' : ' + gdbx.currencySymbol +
			' ' + TooltipFormatter.formatNumberIfDecimal(total);
	}

	public static formatNumberIfDecimal(n: number, decimal: number = this._numDecimal()) {
		// if (n % 1 !== 0) {
		// 	return n.toFixed(decimal);
		// }
		// return n.toFixed(0);
		return addComma(n, decimal, true);
	}

	public static basicTreeMap = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let parentName;
		let parentValue;
		let color;
		const keys = Object.keys(params);
		const values = Object.values(params);

		keys.forEach((key, index) => {
			if (key === 'name') {
				parentName = values[index];
			} else if (key === 'value') {
				parentValue = values[index];
			} else if (key === 'color') {
				color = values[index];
			}
		});
		return colorSpan(color) + ' ' + parentName + ' : ' + gdbx.currencySymbol +
			' ' + TooltipFormatter.formatNumberIfDecimal(parentValue);
	}

	public static treeMapWithoutCurrency = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];
		let parentName;
		let parentValue;
		let color;
		const keys = Object.keys(params);
		const values = Object.values(params);

		keys.forEach((key, index) => {
			if (key === 'name') {
				parentName = values[index];
			} else if (key === 'value') {
				parentValue = values[index];
			} else if (key === 'color') {
				color = values[index];
			}
		});
		return colorSpan(color) + ' ' + parentName + ' : ' +
			' ' + TooltipFormatter.formatNumberIfDecimal(parentValue);
	}

	public static comparisonDay = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];

		const axisValue = parseInt(params[0].axisValue as string, 10) - 1;

		let rez = ''; // params[0].axisValue + '<br />';
		let count = 0;
		// params.sort((a, b) => (b.value as number) - (a.value as number));
		params.forEach((item) => {
			// console.log(item); //quite useful for debug
			const mmt = moment(item.seriesName as any, 'DD MMM YYYY');
			const value = item.value as number;
			const xx =
				colorSpan(item.color) +
				' ' +
				mmt.add(axisValue, 'day').format('DD MMM YYYY') +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		return rez;
	}
	public static comparisonMonth = (param_s) => {
		const colorSpan = TooltipFormatter._colorSpan;
		const params = param_s as EChartsOption[];

		const axisValue = parseInt(params[0].axisValue as string, 10) - 1;

		let rez = ''; // params[0].axisValue + '<br />';
		let count = 0;
		// params.sort((a, b) => (b.value as number) - (a.value as number));
		params.forEach((item) => {
			// console.log(item); //quite useful for debug
			const mmt = moment(item.seriesName as any, 'DD MMM YYYY');
			const value = item.value as number;
			const xx =
				colorSpan(item.color) +
				' ' +
				mmt.add(axisValue, 'month').format('MMM YY') +
				': ' +
				TooltipFormatter.formatNumberIfDecimal(value);
			if (count > 0) {
				rez += '<br />';
			}
			count++;
			rez += xx;
		});
		return rez;
	}



	private static _colorSpan = (color) =>
		'<span style="display:inline-block;margin-right:5px;' +
		'border-radius:10px;width:9px;height:9px;background-color:' +
		color +
		'"></span>'

	private static _numDecimal() {
		return gdbx.numDecimal;
	}
}
