
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import LineChartFilterable from '@/components/reports-v2/components/filterables/LineChartFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import ShowProfitToggle from '@/components/ShowProfitToggle.vue';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import settingx from '@/store/modules/settingx';
import { addComma } from '@/util/number';

@Component({
	components: {
		LineChartFilterable,
		IncludeTaxToggle,
		ShowProfitToggle,
		TableCollapseToggle,
	},
})
export default class SalesInvoiceCreditNoteLineChart extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['dashboard'];
    }

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public xAxisData: string[] = [];
	public periodTotalData: any[] = [];
	public periodTotalFields: any[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get currentMonth(): [number, number] {
		const startMonth = moment(this.selectedAsOfDate).add(-1, 'month').valueOf();
		const endMonth = moment(this.selectedAsOfDate).endOf('day').valueOf();
		return [startMonth, endMonth];
	}

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get showProfit() {
		return settingx.now.showProfit;
	}

	public get color() {
		return this.showProfit
			? [GobiColor.COLOR1, GobiColor.COLOR2, GobiColor.COLOR3]
			: [GobiColor.COLOR1, GobiColor.COLOR3];
	}

	public get expensiveHook() {
		const { showProfit, includeTax, currentMonth } = this;
		return JSON.stringify([showProfit, includeTax, currentMonth]);
	}

	public async expensiveCalc() {
		const salesRef = FilteredDatabase.ref('dashboardSales')
			.dateRange(this.currentMonth)
			.includes('docType', ['IV', 'DN', 'CS']);

		const cnRef = FilteredDatabase.ref('dashboardSales')
			.dateRange(this.currentMonth)
			.includes('docType', ['CN']);

		let pa = 0.3;
		let pb = 0;

		const allAmounts: DimensionData[] = await this._loadDimensionByPeriod(
			salesRef,
			'day',
			pa,
			pb,
			this.includeTax ? 'amountWithTax' : 'amount',
		);

		pb += pa;
		pa = 0.3;

		const allProfits: DimensionData[] = await this._loadDimensionByPeriod(
			salesRef,
			'day',
			pa,
			pb,
			this.includeTax ? 'profitIncludeTax' : 'profit',
		);

		pb += pa;
		pa = 0.3;

		const allCreditNotes: DimensionData[] = await this._loadDimensionByPeriod(
			cnRef,
			'day',
			pa,
			pb,
			this.includeTax ? 'amountIncludeTax' : 'amount',
		);

		this.generateLoadingText(1);

		this.xAxisData = allCreditNotes.map((dd) => dd.text);

		const totalSales = allAmounts.reduce((a, b) => a + b.sum, 0);
		const totalProfit = allProfits
			.map((dd, index) => {
				return dd.sum - allCreditNotes[index].sum;
			})
			.reduce((a, b) => a + b, 0);

		const totalCreditNotes = allCreditNotes.reduce((a, b) => a + b.sum, 0);

		this.periodTotalFields = [
			{
				key: 'total_sales',
				label: 'Total Sales',
			},
			{
				key: 'total_sales_profit',
				label: 'Total Sales Profit',
			},
			{
				key: 'total_credit_notes',
				label: 'Total Credit Note',
			},
		];
		this.periodTotalData = [
			{
				total_sales:
					this.currencySymbol +
					' ' +
					addComma(totalSales, this.numDecimal, true),
				total_sales_profit:
					this.currencySymbol +
					' ' +
					addComma(totalProfit, this.numDecimal, true),
				total_credit_notes:
					this.currencySymbol +
					' ' +
					addComma(totalCreditNotes, this.numDecimal, true),
			},
		];

		pb += pa;
		pa = 0.15;

		const sales = {
			name: 'Sales',
			data: allAmounts.map((item) => item.sum),
			type: 'line',
			areaStyle: {
				color: GobiColor.COLOR1,
				opacity: 1,
			},
			smooth: true,
		};

		const profit = {
			name: 'Profit / Loss',
			data: allProfits.map(
				(item, index) => item.sum - allCreditNotes[index].sum,
			),
			type: 'line',
			areaStyle: {
				color: GobiColor.COLOR2,
				opacity: 1,
			},
			smooth: true,
		};

		const creditNotes = {
			name: 'Credit Notes',
			data: allCreditNotes.map((item) => item.sum),
			type: 'line',
			color: GobiColor.COLOR3,
			smooth: true,
		};

		this.series = this.showProfit
			? [sales, profit, creditNotes]
			: [sales, creditNotes];

		this.saveHistory('series', 'xAxisData', 'periodTotalData');
	}
}
