
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import BaseFilterable from './BaseFilterable.vue';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import DailyNumberDisplayer from '@/components/reports-v2/components/elements/displayers/DailyNumberDisplayer.vue';

@Component({
	components: {
		DailyNumberDisplayer,
		BaseFilterable,
		DataInfo,
	},
})
export default class DailyNumberDisplayerFilterable extends BaseFilterableContainer {
	@Prop({ default: 0 }) public readonly todayAmount!: number;
	@Prop({}) public readonly yesterdayAmount!: number;
	@Prop({}) public readonly bracketAmount?: number;
	@Prop({ default: true }) public readonly isShowFooter!: boolean;
}
