import { render, staticRenderFns } from "./BasicPieChart.vue?vue&type=template&id=8139f1ac&scoped=true"
import script from "./BasicPieChart.vue?vue&type=script&lang=ts"
export * from "./BasicPieChart.vue?vue&type=script&lang=ts"
import style0 from "./BasicPieChart.vue?vue&type=style&index=0&id=8139f1ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8139f1ac",
  null
  
)

export default component.exports