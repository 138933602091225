
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DailyNumberDisplayerFilterable from '@/components/reports-v2/components/filterables/DailyNumberDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import moment from 'moment';
import settingx from '@/store/modules/settingx';
import {PermissionsGroup} from '@/store/models.def';

@Component({
	components: {
		DailyNumberDisplayerFilterable,
		IncludeCreditNoteToggle,
	},
})
export default class TodayProfitNumberDisplayer extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['dashboard'];
    }

	public todayAmount = 0;
	public yesterdayAmount = 0;

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get expensiveHook() {
		const { selectedAsOfDate, includeCreditNote } = this;
		return JSON.stringify([selectedAsOfDate, includeCreditNote]);
	}

	public async expensiveCalc() {
		const mmtToday = moment(this.selectedAsOfDate).endOf('day');
  const mmt7Days = moment(this.selectedAsOfDate).add(-7, 'day');
		// const mmtYesterday = moment(this.selectedAsOfDate).add(-1, 'day');

		const todaySalesRef = FilteredDatabase.ref('dashboardSales')
			.date(mmtToday.year(), mmtToday.month(), mmtToday.date())
			.includes('docType', ['IV', 'DN', 'CS']);

		const todayCNRef = FilteredDatabase.ref('dashboardSales')
			.date(mmtToday.year(), mmtToday.month(), mmtToday.date())
			.includes('docType', ['CN']);

		const yesterdaySalesRef = FilteredDatabase.ref('dashboardSales')
      .dateRange([
        mmt7Days.valueOf(),
        mmtToday.valueOf(),
      ])
			.includes('docType', ['IV', 'DN', 'CS']);

		const yesterdayCNRef = FilteredDatabase.ref('dashboardSales')
        .dateRange([
          mmt7Days.valueOf(),
          mmtToday.valueOf(),
        ])
			.includes('docType', ['CN']);

		const todayProfit = await (await todaySalesRef.get()).getSum('profit');
		const yesterdayProfit = await (
			await yesterdaySalesRef.get()
		).getSum('profit');

		const todayCN = await (await todayCNRef.get()).getSum('profit');
		const yesterdayCN = await (await yesterdayCNRef.get()).getSum('profit');

		this.todayAmount = this.includeCreditNote
			? todayProfit - todayCN
			: todayProfit;
		this.yesterdayAmount = (this.includeCreditNote
			? yesterdayProfit - yesterdayCN
			: yesterdayProfit) / 7;

		this.saveHistory('todayAmount', 'yesterdayAmount');
	}
}
