var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"number-displayer"},[_c('b-row',[_c('b-col',{staticClass:"pt-4 pb-4"},[_c('b-row',{attrs:{"align-h":"center"}},[_c('h4',[_vm._v(_vm._s(_vm.currencySymbol))])]),_c('b-row',{attrs:{"align-h":"center"}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.amountDifference > 0 && _vm.yesterdayAmount !== undefined),expression:"amountDifference > 0 && yesterdayAmount !== undefined"}],staticClass:"fas fa-sort-up fa-2x pr-2",class:{
						'text-cyan':
							_vm.amountDifference > 0 && _vm.yesterdayAmount !== undefined,
						'text-pink':
							_vm.amountDifference < 0 && _vm.yesterdayAmount !== undefined,
					}}),_c('h2',[_vm._v(" "+_vm._s(_vm.numberText)+" "),(_vm.bracketAmount !== undefined)?_c('span',{staticStyle:{"font-size":"1.2rem"}},[_vm._v("("+_vm._s(_vm.bracketAmount)+")")]):_vm._e()])]),(_vm.yesterdayAmount !== undefined && _vm.yesterdayAmount !== null)?_c('b-row',{attrs:{"align-h":"center"}},[_c('p',{staticClass:"font-weight-bold",class:{
						'text-cyan':
							_vm.amountDifference > 0 && _vm.yesterdayAmount !== undefined,
						'text-pink':
							_vm.amountDifference < 0 && _vm.yesterdayAmount !== undefined,
					}},[_vm._v(" "+_vm._s(_vm.amountDifferenceText)+" ("+_vm._s(_vm.amountDifferenceMargin)+"%) ")])]):_vm._e()],1)],1),(_vm.isShowFooter)?_c('p',{staticClass:"text-gray-600 date"},[_vm._v(_vm._s(_vm.footer))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }