
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DailyNumberDisplayerFilterable from '@/components/reports-v2/components/filterables/DailyNumberDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {DashboardQuotation, PermissionsGroup} from '@/store/models.def';
import moment from 'moment';

@Component({
	components: {
		DailyNumberDisplayerFilterable,
	},
})
export default class TodayQuotationNumberDisplayer extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['dashboard'];
    }

	public todayAmount = 0;
	public yesterdayAmount = 0;
	public bracketAmount = 0;

	public get expensiveHook() {
		const { selectedAsOfDate } = this;
		return JSON.stringify([selectedAsOfDate]);
	}

	public async expensiveCalc() {
		const mmtToday = moment(this.selectedAsOfDate).endOf('day');
  const mmt7Days = moment(this.selectedAsOfDate).add(-7, 'day');
		const mmtYesterday = moment(this.selectedAsOfDate).add(-1, 'day');

		const todayRef = FilteredDatabase.ref('dashboardQuotation').date(
			mmtToday.year(),
			mmtToday.month(),
			mmtToday.date(),
		);

		const yesterdayRef = FilteredDatabase.ref('dashboardQuotation').dateRange([
      mmt7Days.valueOf(), mmtToday.valueOf(),
    ]);

		const todayRefHead = await todayRef.get();
		const yesterdayRefHead = await yesterdayRef.get();

		const todayCards: DashboardQuotation[] = await todayRefHead.getCards();
		const yesterdayCards: DashboardQuotation[] =
			await yesterdayRefHead.getCards();

		const todayQuotation = todayCards.reduce((a, b) => a + b.amount, 0);
		const yesterdayQuotation = yesterdayCards.reduce((a, b) => a + b.amount, 0);

		this.todayAmount = todayQuotation;
		this.yesterdayAmount = (yesterdayQuotation) / 7;
		this.bracketAmount = todayCards.length;

		this.saveHistory('todayAmount', 'yesterdayAmount', 'bracketAmount');
	}
}
