
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ListFilter from './ListFilter.vue';
import BaseFilter from './BaseFilter.vue';
import { ValueText } from '@/store/models.def';

@Component({
	components: {
		ListFilter,
	},
})
export default class ListFilterContainer extends BaseFilter {
	@Prop({ default: () => [] }) public value!: string[];

	public selected: string[] = this.value;

	public mounted() {
		this.selected = this.selectAllIfEmpty;
	}

	public get options(): ValueText[] {
		return [];
	}

	public onHovering(value: boolean, id: string) {
		this._emitIsHovering(value);
	}
	public onEditing(value: boolean, id: string) {
		this._emitIsEditing(value);
	}
	public get selectAllIfEmpty(): string[] {
		return this.value.length === 0 && this.options.length > 0
			? this.options.map((vt) => vt.value).sort()
			: this.value;
	}
	@Watch('selected')
	public onSelectedChange(newVal: string[], oldVal: string[]) {
		if (this.selected !== this.value) {
			this.$emit('input', this.selected);
		}
	}
	@Watch('value')
	public onValueChange(newVal: string[], oldVal: string[]) {
		this.selected = newVal;
	}
	@Watch('options')
	public onOptionsChange(newVal: string[], oldVal: string[]) {
		this.selected = this.selectAllIfEmpty;
	}
}
