
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { PermissionsGroup } from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import settingx from '@/store/modules/settingx';
import moment from 'moment';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';

@Component({
	components: { TableFilterable, IncludeTaxToggle, IncludeCreditNoteToggle },
})
export default class SalesCustomerRankingTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['sales'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['date'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Sales Customer Ranking' + '_' + formattedDate;
	}

	public get dateFormatted() {
		return this.selectedAsOfDate;
	}

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get expensiveHook() {
		const {
			includeTax,
			selectedAsOfDate,
			selectedCustomers,
			includeCreditNote,
		} = this;
		return JSON.stringify([
			includeTax,
			selectedAsOfDate,
			selectedCustomers,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const mmt = moment(this.selectedAsOfDate);

		const ref = FilteredDatabase.ref('dashboardSales')
			.customers(this.selectedCustomers)
			.date(mmt.year(), mmt.month(), mmt.date());

		const customerList = [...(await (await ref.get()).getSet('code'))];

		const salesRef = ref
			.clone()
			.includes('code', customerList)
			.includes('docType', ['IV', 'DN', 'CS']);

		const cnRef = FilteredDatabase.ref('dashboardSales')
			.customers(customerList)
			.date(mmt.year(), mmt.month(), mmt.date())
			.includes('docType', ['CN']);

		this.tableFields = [
			{ key: 'customer_name', sortable: true, stickyColumn: true },
			{
				key: 'total',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
      {
        key: 'profit',
        sortable: true,
        formatter: TableItemFormatter.currency,
      },
		];

		let pa = 0.33;
		let pb = 0;

		const salesDD = await this._loadDimensionByFilters(
			salesRef,
			'customers',
			pa,
			pb,
			this.includeTax ? 'amountWithTax' : 'amount',
		);

		pb += pa;
		pa = 0.33;

  const profitDD = await this._loadDimensionByFilters(
        salesRef,
        'customers',
        pa,
        pb,
        'profit',
    );

  pb += pa;
  pa = 0.33;

		const CNDD = await this._loadDimensionByFilters(
			cnRef,
			'customers',
			pa,
			pb,
			this.includeTax ? 'amountWithTax' : 'amount',
		);

		const result = salesDD.map((dd, index) => {
			const item: any = {
				total: dd.sum - (this.includeCreditNote ? CNDD[index].sum : 0),
				customer_name: dd.text,
        profit: profitDD[profitDD.findIndex((a) => {
          return a.text === dd.text;
        })].sum,
			};

			return item;
		});

		this.tableItems = result.filter((item) => item.total !== 0);

		this.saveHistory('tableItems', 'tableFields');
	}
}
