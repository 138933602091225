
import 'vue2-datepicker/index.css';
import moment, {min} from 'moment';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseFilter from './BaseFilter.vue';
import Calendar from 'primevue/calendar';

@Component({
    methods: {min},
	components: {
		Calendar,
	},
})
export default class DateRangeFilter extends BaseFilter {
	@Prop({ default: [moment().valueOf(), moment().valueOf()] })
	public value!: number[];
	@Prop({}) public dateType?: string;
	@Prop({ default: true }) public showHeader!: boolean;
	@Prop({ default: true }) public showFooter!: boolean;
    @Prop({ default: null }) public minDate!: boolean;
    @Prop({ type: Boolean, default: false }) public enableMaxDate!: boolean;

	public selectedDateType: string | any = 'day';
	public componentKey: number = 0;
	public isChangeViewBy: boolean = false;
	public defaultDateFormat: string = 'dd M yy';

	public viewByButtons = [
		{ type: 'date', value: true },
		{ type: 'month', value: false },
		{ type: 'year', value: false },
	];

	public selectedDateRange: Date[] | Date = [
		new Date(this.value[0]),
		new Date(this.value[1]),
	];

	public assignDateType(value) {
		this.isChangeViewBy = true;
		this.selectedDateType = value;
		this.componentKey += 1;
		if (value === 'date') {
			this.viewByButtons[1].value = false;
			this.viewByButtons[2].value = false;
		} else if (value === 'month') {
			this.viewByButtons[0].value = false;
			this.viewByButtons[2].value = false;
		} else {
			this.viewByButtons[0].value = false;
			this.viewByButtons[1].value = false;
		}
	}

    public get getMaxDate() {
        if (this.selectedDateRange[1]) {
            return null;
        }
        if (this.selectedDateRange[0] === this.selectedDateRange[1]) {
            return new Date(moment(this.selectedDateRange[0]).add(12, 'months').valueOf());
        }
        return new Date(moment(this.selectedDateRange[0]).add(12, 'months').add(-1, 'days').valueOf());
    }

	public selectCalenderType(emit, value, type) {
		const start = moment().startOf('day').add(value, type).toDate();
		const end = moment().endOf('day').add(-1, type).toDate();
		const date = [start, end];
		emit(date);
	}

	public onHovering(value: boolean, id: string) {
		this._emitIsHovering(value);
	}

	public onEditing(value: boolean, id: string) {
		this._emitIsEditing(value);
	}
	public onFormatOfDate(newVal) {
		if (newVal === 'month') {
			return 'M yy';
		} else if (newVal === 'year') {
			return 'yy';
		} else {
			return 'dd M yy';
		}
	}

	@Watch('value')
	public onValueChange(newVal: Date[] | Date, oldVal: Date[] | Date) {
		this.selectedDateRange = [
			new Date(moment(newVal[0]).startOf(this.selectedDateType).valueOf()),
			new Date(moment(newVal[1]).endOf(this.selectedDateType).valueOf()),
		];
		this.onFormatOfDate(this.selectedDateType);
	}

	@Watch('selectedDateRange')
	public formatDate() {
		if (
			this.selectedDateRange[0] !== null &&
			this.selectedDateRange[1] !== null
		) {
			const newVal = [this.selectedDateRange[0].valueOf()];
			newVal.push(this.selectedDateRange[1].valueOf());
			if (JSON.stringify(this.value) !== JSON.stringify(newVal)) {
				this.$emit('input', newVal);
			}
		}
	}

	@Watch('dateType', { immediate: true })
	public onDateTypeChange(newVal: string) {
		this.assignDateType(newVal);
		this.onFormatOfDate(newVal);
	}
}
