export class LocalData {

    public static save(key: string, item: any) {
        const str = JSON.stringify(item);
        localStorage.setItem(key, str);
        LocalData._setCookie(key, str);
    }
    public static load(key: string, asString = false) {
        let loadedData = localStorage.getItem(key);
        if (!loadedData) {
            loadedData = LocalData._getCookie(key);
            if (!loadedData) { return null; }
            localStorage.setItem(key, loadedData);
        }
        return asString ? loadedData : JSON.parse(loadedData);
    }

    private static _setCookie(cname: string, cvalue: string, exdays: number = 30) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    private static _getCookie(cname: string) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}
