
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import BaseLineChart from '@/components/reports-v2/components/elements/charts/BaseLineChart.vue';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import { TooltipFormatter } from '../elements/charts/helpers/tooltipFormatter';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import BaseFilterable from './BaseFilterable.vue';
import { GobiColor } from '../../../../helpers/color';
import { DataViewFormatter } from '../elements/charts/helpers/dataViewFormatter';
import { DataZoomList } from '../elements/charts/helpers/dataZoom';

@Component({
	components: {
		BaseLineChart,
		DataInfo,
		BaseFilterable,
	},
})
export default class LineChartFilterable extends BaseFilterableContainer {
	// x-Axis
	@Prop({ default: () => [] }) public readonly xAxisData!: any[];
	@Prop({ default: '' }) public readonly xAxisLabel!: string;
	@Prop({ default: '' }) public readonly xAxisType!: any;
	// y-Axis
	@Prop({ default: '' }) public readonly yAxisLabel!: string;
	@Prop({ default: 'value' }) public readonly yAxisType!: any;
	// Formatter
	@Prop({ default: () => TooltipFormatter.lineChart })
	public readonly formatter!: any;
	@Prop({ default: () => DataViewFormatter.basic })
	public readonly dataViewFormatter!: any;
	// Other
	@Prop({ default: () => DataZoomList.xAxis('slider', 100, 0) })
	public readonly dataZoom!: echarts.ECharts;
	@Prop({})
	public readonly legends!: echarts.ECharts;
	@Prop({
		default: () => [
			GobiColor.COLOR1,
			GobiColor.COLOR2,
			GobiColor.COLOR3,
			GobiColor.COLOR4,
			GobiColor.COLOR5,
			GobiColor.COLOR6,
		],
	})
	public readonly color!: string[];
	@Prop({ default: () => [] })
	public readonly series!: echarts.ECharts;
	@Prop({ default: () => [] }) public readonly selectedAgentValue!: string[];
	@Prop({ default: 'auto' })
	public readonly chartHeight!: string;

	public get printedTitle() {
		return `${this.title} - ${this.dateFormatted}`;
	}
}
