
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DailyNumberDisplayerFilterable from '@/components/reports-v2/components/filterables/DailyNumberDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { DashboardData } from '@/store/models.def';

@Component({
	components: {
		DailyNumberDisplayerFilterable,
	},
})
export default class TodayCashFlowDisplayer extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| ''
	> = [''];

	public todayAmount = 0;
	public yesterdayAmount = 0;

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedStockItems } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.5;
		let pb = 0;

		const mmt = moment(this.selectedAsOfDate);

		const ref = FilteredDatabase.ref('dashboardData');

		const refHead = await ref.get();
		const cards: DashboardData[] = await refHead.getCards();

		pb += pa;
		pa = 0.5;

		this.todayAmount = cards[0].todayCashFlow;
		this.yesterdayAmount = cards[0].ytdCashFlow;

		this.saveHistory('todayAmount', 'yesterdayAmount');
	}
}
