
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ListFilter from './ListFilter.vue';
import ListFilterContainer from './ListFilterContainer.vue';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		ListFilter,
	},
})
export default class AgentsListFilter extends ListFilterContainer {
	@Prop({ default: false }) public isShowCode!: boolean;

	public get options() {
		return this.isShowCode ? gdbx.allAgentsCodeOnly : gdbx.allAgents;
	}
}
