
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import LineChartFilterable from '@/components/reports-v2/components/filterables/LineChartFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DashboardData } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import { addComma } from '@/util/number';

@Component({
	components: {
		LineChartFilterable,
		TableCollapseToggle,
	},
})
export default class SevenDaysSalesPerformanceLineChart extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| ''
	> = [''];

	public periodTotalData: any[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get week(): [number, number] {
		const day = moment(this.selectedAsOfDate);
		day.add(-6, 'day');
		return [day.valueOf(), this.selectedAsOfDate];
	}

	public get xAxisData() {
		const mmt = moment(this.week[0]).startOf('day');
		const lastDay = this.week[1];
		const result: string[] = [];
		while (mmt.valueOf() < lastDay) {
			result.push(mmt.format('DD MMM YYYY'));
			mmt.add(1, 'day');
		}
		return result;
	}

	public get expensiveHook() {
		const {
			week,
			selectedAsOfDate,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		} = this;
		return JSON.stringify([
			week,
			selectedAsOfDate,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		let pa = 0.5;
		let pb = 0;

		const ref = FilteredDatabase.ref('dashboardData');

		const refHead = await ref.get();
		const cards: DashboardData[] = await refHead.getCards();

		pb += pa;
		pa = 0.5;

		const datas = [
			cards[0].sales6,
			cards[0].sales5,
			cards[0].sales4,
			cards[0].sales3,
			cards[0].sales2,
			cards[0].sales1,
			cards[0].sales0,
		];

		const total = datas.reduce((a, b) => a + b, 0);

		this.periodTotalData = [
			{
				total_sales:
					this.currencySymbol + ' ' + addComma(total, this.numDecimal, true),
			},
		];

		pb += pa;
		pa = 0.15;

		const sales = {
			name: 'Sales',
			data: datas,
			type: 'line',
			areaStyle: {
				color: GobiColor.TEAL,
				opacity: 1,
			},
			smooth: true,
		};

		this.series = [sales];

		this.saveHistory('series', 'periodTotalData');
	}
}
