
import AutoResizeSticky from '@/components/AutoResizeSticky.vue';

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {
		AutoResizeSticky,
	},
})
export default class StickyFilterContainer extends Vue {}
