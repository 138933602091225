
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class BaseFilter extends Vue {
    @Prop({required: true}) public filterId!: string;

    public isHovering: boolean = false;
    public isEditing: boolean = false;
    @Watch('isHovering')
    public onIsHoveringChange(newValue: boolean, oldValue: boolean) {
        this._emitIsHovering(newValue);
    }
    @Watch('isEditing')
    public onIsEditingChange(newValue: boolean, oldValue: boolean) {
        this._emitIsEditing(newValue);
    }

	public beforeDestroy() {
		this.$emit('hovering', false, this.filterId);
		this.$emit('editing', false, this.filterId);
	}
    protected _emitIsHovering(value: boolean) {
        this.$emit('hovering', value, this.filterId);
    }
    protected _emitIsEditing(value: boolean) {
        this.$emit('editing', value, this.filterId);
	}
}
