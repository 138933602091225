
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ListFilterContainer from './ListFilterContainer.vue';
import BaseFilter from './BaseFilter.vue';
import Calendar from 'primevue/calendar';

@Component({
	components: {
		DatePicker,
		Calendar,
	},
})
export default class DateFilter extends BaseFilter {
	@Prop({ default: 'primary' }) public variant!: string;
	@Prop({ default: moment().valueOf() }) public value!: number;
	@Prop({}) public dateType?: string;
	// public selectedDay: [number, number] = [0, 0];
	public selectedDay: Date = new Date(this.value);
	@Prop({ default: false }) public disabled!: boolean;

	public selectToday(emit) {
		const day = moment().endOf('day').toDate();
		emit(day);
	}

	public selectYesterday(emit) {
		const day = moment().endOf('day').add(-1, 'day').toDate();
		emit(day);
	}

	public onHovering(value: boolean, id: string) {
		this._emitIsHovering(value);
	}

	public onEditing(value: boolean, id: string) {
		this._emitIsEditing(value);
	}

	public get showButtonBar() {
		return this.dateType !== 'month' && this.dateType !== 'year';
	}

	public get dateFormat() {
		if (this.dateType === 'month') {
			return 'M yy';
		} else if (this.dateType === 'year') {
			return 'yy';
		} else {
			return 'dd M yy';
		}
	}

	@Watch('value')
	public onValueChange(newVal: Date, oldVal: Date) {
		this.selectedDay = new Date(newVal);
	}

	@Watch('selectedDay')
	public formatDate() {
		if (this.selectedDay !== null) {
			const newVal = this.selectedDay.valueOf();
			if (this.value !== newVal) {
				this.$emit('input', newVal);
			}
		}
	}
}
