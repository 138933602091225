
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import settingx from '@/store/modules/settingx';
import Checkbox from 'primevue/checkbox';

@Component({
	components: { Checkbox },
})
export default class IncludeCreditNoteToggle extends Vue {
	public localIncludeCreditNote: boolean = this.globalIncludeCreditNote;

	public get globalIncludeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	@Watch('localIncludeCreditNote')
	public onLocalIncludeCreditNoteChanged(newVal) {
		settingx.updateSettings({ includeCreditNote: newVal });
	}

	@Watch('globalIncludeCreditNote')
	public onGlobalIncludeCreditNoteChanged(newVal) {
		this.localIncludeCreditNote = newVal;
	}
}
