
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TodaySalesNumberDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodaySalesNumberDisplayer.vue';
import TodayProfitNumberDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodayProfitNumberDisplayer.vue';
import TodayQuotationNumberDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodayQuotationNumberDisplayer.vue';
import SevenDaysSalesPerformanceLineChart from '@/components/reports-v2/components/codedWidgets/dashboard/SevenDaysSalesPerformanceLineChart.vue';
import StockGroupPerformancePieChart from '@/components/reports-v2/components/codedWidgets/dashboard/StockGroupPerformancePieChart.vue';
import TodayCustomerPaymentDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodayCustomerPaymentDisplayer.vue';
import TodaySupplierPaymentDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodaySupplierPaymentDisplayer.vue';
import TodayCashFlowDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/TodayCashFlowDisplayer.vue';
import CashOnHandDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/CashOnHandDisplayer.vue';
import CashInBankDisplayer from '@/components/reports-v2/components/codedWidgets/dashboard/CashInBankDisplayer.vue';
import TodayCustomerDueTable from '@/components/reports-v2/components/codedWidgets/dashboard/TodayCustomerDueTable.vue';
import TodaySupplierDueTable from '@/components/reports-v2/components/codedWidgets/dashboard/TodaySupplierDueTable.vue';
import AgentsListFilter from '@/components/reports-v2/components/filters/AgentsListFilter.vue';
import CustomerListFilter from '@/components/reports-v2/components/filters/CustomerListFilter.vue';
import SupplierListFilter from '@/components/reports-v2/components/filters/SupplierListFilter.vue';
import StockListFilter from '@/components/reports-v2/components/filters/StockListFilter.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import AutoResizeSticky from '@/components/AutoResizeSticky.vue';
import moment from 'moment';
import gdbx from '../../../../store/modules/gdbx';
import TitleBar from '@/components/reports-v2/pages/dashboard/TitleBar.vue';

@Component({
	components: {
		TitleBar,
		TodayCustomerDueTable,
		TodaySupplierDueTable,
		TodaySalesNumberDisplayer,
		TodayProfitNumberDisplayer,
		TodayQuotationNumberDisplayer,
		SevenDaysSalesPerformanceLineChart,
		AgentsListFilter,
		StockListFilter,
		DateRangeFilter,
		DateFilter,
		CustomerListFilter,
		SupplierListFilter,
		StockGroupPerformancePieChart,
		TodayCustomerPaymentDisplayer,
		TodaySupplierPaymentDisplayer,
		TodayCashFlowDisplayer,
		CashOnHandDisplayer,
		CashInBankDisplayer,
	},
})
export default class NewSummaries extends Vue {
	public get selectedAgents() {
		return gdbx.allAgents.map((vt) => vt.value);
	}
	public get selectedStockItems() {
		return gdbx.allStockItems.map((vt) => vt.value);
	}

	public selectedAsOfDate: number = moment().endOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment(this.selectedAsOfDate).add(-1, 'year').startOf('day').valueOf(),
		moment(this.selectedAsOfDate).endOf('day').valueOf(),
	];
}
