export class GobiColor {
	public static readonly TEAL = '#662D91';
	public static readonly YELLOW = '#F6E73E';
	public static readonly DEEP_TEAL = '#408EA8';
	public static readonly ORANGE = '#FFAF34';
	public static readonly PINK = '#F291A4';
	public static readonly DARK_TEAL = '#136466';
	public static readonly BLUE = '#0273F3';
	public static readonly PURPLE = '#EAD8F6';
	public static readonly GREEN = '#00FF73';
	public static readonly BG_GRAY = '#F3F5F7';
	public static readonly GRAY = '#dddfeb';
	public static readonly RED = '#E0272E';

	public static readonly COLOR1 = '#876BED';
	public static readonly COLOR2 = '#999FFF';
	public static readonly COLOR3 = '#D0D7F3';
	public static readonly COLOR4 = '#ACC0FF';
	public static readonly COLOR5 = '#ACDAFF';
	public static readonly COLOR6 = '#ADB3C4';
	public static readonly COLOR7 = '#658AB2';
}

