
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DailyNumberDisplayerFilterable from '@/components/reports-v2/components/filterables/DailyNumberDisplayerFilterable.vue';
import moment from 'moment';
import uuid from 'uuid';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { DashboardData } from '@/store/models.def';

@Component({
	components: {
		DailyNumberDisplayerFilterable,
	},
})
export default class TodaySupplierPaymentDisplayer extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| ''
	> = [''];

	public todayAmount = 0;

	public get expensiveHook() {
		const { selectedAsOfDate, selectedAgents, selectedStockItems } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		const jobId = (this.currentJobId = uuid.v4());

		let pa = 0.5;
		let pb = 0;

		const mmt = moment(this.selectedAsOfDate);

		const ref = FilteredDatabase.ref('dashboardData');

		const refHead = await ref.get();
		const cards: DashboardData[] = await refHead.getCards();

		pb += pa;
		pa = 0.5;

		this.todayAmount = cards[0].supplierPayment;

		this.saveHistory('todayAmount');
	}
}
