
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts/core';
import BasicPieChart from '@/components/reports-v2/components/elements/charts/BasicPieChart.vue';
import gdbx from '@/store/modules/gdbx';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import { TooltipFormatter } from '../elements/charts/helpers/tooltipFormatter';
import { LabelFormatter } from '../elements/charts/helpers/labelFormatter';
import { addComma } from '@/util/number';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import BaseFilterable from './BaseFilterable.vue';
import { DataViewFormatter } from '../elements/charts/helpers/dataViewFormatter';

@Component({
	components: {
		BasicPieChart,
		DataInfo,
		BaseFilterable,
	},
})
export default class PieChartFilterable extends BaseFilterableContainer {
	@Prop({}) public readonly legends?: echarts.EChartsCoreOption;
	@Prop({ default: 'Total' }) public readonly totalName?: string;
	@Prop({ default: () => [] })
	public readonly pieDataValue!: Array<
		{ name: string; value: number } | echarts.EChartsCoreOption | any
	>;
	@Prop({ default: () => TooltipFormatter.pieChart })
	public readonly tooltipFormatter!: (any) => string;
	@Prop({ default: () => LabelFormatter.pieChart })
	public readonly labelFormatter!: (any) => string;
	@Prop({ default: () => DataViewFormatter.pieChart })
	public readonly dataViewFormatter!: (any) => string;
	@Prop({ default: true }) public readonly showTotal!: boolean;
	@Prop({ default: () => ['0%', '50%'] }) public readonly radius!: string[];
	@Prop({
		default: () => (amount: number) =>
			`${gdbx.currencySymbol} ${addComma(amount, gdbx.numDecimal, true, true)}`,
	})
	public readonly totalAmountFormatter!: (any) => string;

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get totalAmount() {
		return this.pieDataValue.reduce((c, n) => c + n.value, 0);
	}
	public get totalAmountFormatted() {
		return this.totalAmountFormatter(this.totalAmount);
	}

	public get series(): echarts.ECharts {
		const pie: echarts.EChartsCoreOption | any = {
			name: '',
			type: 'pie',
			radius: this.radius,
			itemStyle: {
				borderRadius: 10,
				borderColor: '#fff',
				borderWidth: 2,
			},
			label: {
				show: true,
				position: 'outside',
				formatter: this.labelFormatter,
			},
			data: [] as number[],
			universalTransition: true,
		};
		const hasNon0 = this.totalAmount > 0;
		for (const original of this.pieDataValue) {
			const clone: echarts.EChartsCoreOption = {
				...original,
			};
			if (hasNon0 && clone.value === 0) {
				clone.label = { show: false };
				clone.labelLine = { show: false };
			}
			pie.data.push(clone);
		}

		const result = pie;
		return result;
	}
}
