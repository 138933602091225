
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PieChartFilterable from '@/components/reports-v2/components/filterables/PieChartFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { DimensionData, DimensionMap } from '@/store/models.def';
import moment from 'moment';

@Component({
	components: {
		PieChartFilterable,
	},
})
export default class StockGroupPerformancePieChart extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| ''
	> = [''];

	public pieDatas: Array<{ name: string; value: number }> = [];

	public get week(): [number, number] {
		const day = moment(this.selectedAsOfDate);
		day.add(-6, 'day');
		return [day.startOf('day').valueOf(), this.selectedAsOfDate];
	}

	public get expensiveHook() {
		const {
			week,
			selectedAsOfDate,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		} = this;
		return JSON.stringify([
			week,
			selectedAsOfDate,
			selectedDateRange,
			selectedAgents,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		const ref = FilteredDatabase.ref('dashboardStockGroupSales');

		let pa = 0.5;
		let pb = 0;

		const stockGroupList = [...(await (await ref.get()).getSet('stockGroup'))];

		const map: DimensionMap[] = stockGroupList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'stockGroup',
			value: [code],
			text: code,
		}));

		// const refHead = await ref.get();

		pb += pa;
		pa = 0.5;

		let datas: DimensionData[] = await this._loadDimension(
			ref,
			map,
			pa,
			pb,
			'amount',
		);

		datas = FilterWidget.limitSlices(datas, 10);

		this.pieDatas = datas.map((dd) => ({
			name: dd.text,
			value: dd.sum,
		}));

		// const allCards: DashboardStockGroupSales[] = await refHead.getCards();

		// const result = {};
		// for (const item of allCards) {
		// 	if (!result[item.stockGroup]) {
		// 		result[item.stockGroup] = [];
		// 	}
		// 	result[item.stockGroup].push(item);
		// }

		// this.pieDatas = [];

		// for (const key in result) {
		// 	if (result.hasOwnProperty(key)) {
		// 		const cards: DashboardStockGroupSales[] = result[key];

		// 		const item = { name: key, value: 0 };

		// 		item.value = cards.reduce((a, b) => a + b.amount, 0);
		// 		this.pieDatas.push(item);
		// 	}
		// }

		this.saveHistory('pieDatas');
	}
}
