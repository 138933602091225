
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import LineChartFilterable from '@/components/reports-v2/components/filterables/LineChartFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionData, PermissionsGroup } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import settingx from '@/store/modules/settingx';
import { addComma } from '@/util/number';

@Component({
	components: {
		LineChartFilterable,
		IncludeTaxToggle,
		TableCollapseToggle,
	},
})
export default class QuotationLineChart extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['dashboard'];
    }

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public xAxisData: string[] = [];
	public periodTotalData: any[] = [];
	public periodTotalFields: any[] = [];
	public series: echarts.ECharts[] | any = [];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get currentMonth(): [number, number] {
		const startMonth = moment(this.selectedAsOfDate).startOf('month').valueOf();
		const endMonth = moment(this.selectedAsOfDate).endOf('day').valueOf();
		return [startMonth, endMonth];
	}

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get expensiveHook() {
		const { includeTax, currentMonth } = this;
		return JSON.stringify([includeTax, currentMonth]);
	}

	public async expensiveCalc() {
		const quotationRef = FilteredDatabase.ref('dashboardQuotation').dateRange(
			this.currentMonth,
		);

		const allQuotations: DimensionData[] = await this._loadDimensionByPeriod(
			quotationRef,
			'day',
			1,
			0,
			this.includeTax ? 'amountWithTax' : 'amount',
		);

		const allConvertedQuotations: DimensionData[] =
			await this._loadDimensionByPeriod(
				quotationRef,
				'day',
				1,
				0,
				this.includeTax ? 'transferredAmountWithTax' : 'transferredAmount',
			);

		this.xAxisData = allQuotations.map((dd) => dd.text);

		const totalQuotation = allQuotations.reduce((a, b) => a + b.sum, 0);
		const totalConvertedQuotation = allConvertedQuotations.reduce(
			(a, b) => a + b.sum,
			0,
		);

		this.periodTotalFields = [
			{
				key: 'total_quotation',
				label: 'Total Quotation',
			},
			{
				key: 'total_converted_quotation',
				label: 'Total Converted Quotation',
			},
		];

		this.periodTotalData = [
			{
				total_quotation:
					this.currencySymbol +
					' ' +
					addComma(totalQuotation, this.numDecimal, true),
				total_converted_quotation:
					this.currencySymbol +
					' ' +
					addComma(totalConvertedQuotation, this.numDecimal, true),
			},
		];

		const quotation = {
			name: 'Quotation',
			data: allQuotations.map((item) => item.sum),
			type: 'line',
			areaStyle: {
				color: GobiColor.COLOR1,
				opacity: 1,
			},
			smooth: true,
		};

		const convertedQuotation = {
			name: 'Converted Quotation',
			data: allConvertedQuotations.map((item) => item.sum),
			type: 'line',
			areaStyle: {
				color: GobiColor.COLOR2,
				opacity: 1,
			},
			smooth: true,
		};

		this.series = [quotation, convertedQuotation];

		this.saveHistory('series', 'xAxisData', 'periodTotalData');
	}
}
