
import {Component, Vue} from 'vue-property-decorator';
import accountx from '@/store/modules/accountx';
import moment from 'moment';

@Component({
	components: {},
})
export default class BaseReport extends Vue {
	public get lastSync() {
		return (
			accountx.fullAccountData &&
			moment(accountx.fullAccountData.lastSync).format('DD MMM YYYY hh:mm A')
		);
	}

	public static groupCheckDecimal(values: number[], shorten: boolean = false) {
		if (values.findIndex((n) => n % 1 !== 0)) {
			// some of the numbers has decimal
			return values.map((value) =>
				shorten ? BaseReport.shortenNumber(value) : value.toFixed(2),
			);
		}
		return values.map((value) =>
			shorten ? BaseReport.shortenNumber(value) : value.toFixed(0),
		);
	}

	public static shortenNumber(num: any) {
    // if (abs < 100000) {
		// 	return addComma(num);
		// }
		// if (abs < 1000000) {
		// 	return addComma(num / 1000, 3, false, false) + ' K';
		// }
		// return addComma(num / 1000000, 3, false, false) + ' M';
  return ((num));
	}

	public currentHoveringFilterId: string | null = null;
	public currentEditingFilterId: string | null = null;

	public mounted() {
		this.$emit('isReport', true);
	}
	public beforeDestroy() {
		this.$emit('isReport', false);
	}

	public onHovering(value: boolean, filterId: string) {
		if (value) {
			this.currentHoveringFilterId = filterId;
		} else if (this.currentHoveringFilterId === filterId) {
			this.currentHoveringFilterId = null;
		}
	}
	public onEditing(value: boolean, filterId: string) {
		if (value) {
			this.currentEditingFilterId = filterId;
		} else if (this.currentEditingFilterId === filterId) {
			this.currentEditingFilterId = null;
		}
	}
	public get currentFocusingFilterId() {
		return this.currentHoveringFilterId || this.currentEditingFilterId;
	}
}
(window as any).BaseReport = BaseReport;
