import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import {
	SettingsSubmission,
} from '@/store/models.def';
import { LocalData } from '@/util/localData';

@Module({
	namespaced: true,
	name: 'settingx',
	store,
	dynamic: true,
})
class GobiSettingsModule extends VuexModule {

	private readonly _default = {
		local: {
			includeTax: false,
			showProfit: true,
			includeCreditNote: true,
			includeDebitNote: false,
			highlightAccount: false,
		},
		server: {
		},
	};
	public get now(): {
		includeTax: boolean,
		showProfit: boolean,
		includeCreditNote: boolean,
		includeDebitNote: boolean,
		highlightAccount: boolean,
	} {
		return { ...this._local, ...this._server };
	}

	private _local = { ...this._default.local, ...LocalData.load('gobiLocalSettings') } || { ...this._default.local };
	private _server = { ...this._default.server, ...LocalData.load('gobiServerSettings') } || { ...this._default.server };

	@Mutation
	public m_updateSettings(settings: SettingsSubmission) {
		const localSettings = Object.keys(this._default.local);
		const serverSettings = Object.keys(this._default.server);

		let localDirty = false;
		let serverDirty = false;

		for (const key in settings) {
			if (settings.hasOwnProperty(key)) {
				if (serverSettings.includes(key)) {
					this._server[key] = settings[key];
					serverDirty = true;
					// TODO: save to server
				} else if (localSettings.includes(key)) {
					this._local[key] = settings[key];
					localDirty = true;
				}
			}
		}
		if (localDirty) {
			LocalData.save('gobiLocalSettings', this._local);
		}
		if (serverDirty) {
			// TODO: save to server
		}
	}

	@Action
	public updateSettings(settings: SettingsSubmission) {
		this.m_updateSettings(settings);
	}
	@Action
	public backToDefault(target: 'local' | 'server') {
		switch (target) {
			case 'local':
				this.updateSettings(this._default.local);
				break;
			case 'server':
				this.updateSettings(this._default.server);
				break;
		}
	}

}
export default getModule(GobiSettingsModule);
