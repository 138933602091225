
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import IncludeTaxToggle from '@/components/IncludeTaxToggle.vue';
import IncludeCreditNoteToggle from '@/components/IncludeCreditNoteToggle.vue';
import settingx from '@/store/modules/settingx';
import moment from 'moment';
import {PermissionsGroup} from '@/store/models.def';

@Component({
	components: { TableFilterable, IncludeTaxToggle, IncludeCreditNoteToggle },
})
export default class SalesAgentRankingTable extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['dashboard'];
    }

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Sales Agent Ranking' + '_' + formattedDate;
	}

	public get dateFormatted() {
		return this.selectedAsOfDate;
	}

	public get includeTax() {
		return settingx.now.includeTax;
	}

	public get includeCreditNote() {
		return settingx.now.includeCreditNote;
	}

	public get expensiveHook() {
		const { includeTax, selectedAsOfDate, selectedAgents, includeCreditNote } =
			this;
		return JSON.stringify([
			includeTax,
			selectedAsOfDate,
			selectedAgents,
			includeCreditNote,
		]);
	}

	public async expensiveCalc() {
		const mmt = moment(this.selectedAsOfDate);

		const ref = FilteredDatabase.ref('dashboardSales')
			.agents(this.selectedAgents)
			.date(mmt.year(), mmt.month(), mmt.date());

		const agentList = [...(await (await ref.get()).getSet('agent'))];

		const salesRef = ref
			.clone()
			.includes('agent', agentList)
			.includes('docType', ['IV', 'DN', 'CS']);

		const cnRef = FilteredDatabase.ref('dashboardSales')
			.agents(agentList)
			.date(mmt.year(), mmt.month(), mmt.date())
			.includes('docType', ['CN']);

		this.tableFields = [
			{ key: 'agent_name', sortable: true, stickyColumn: true },
			{
				key: 'total',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
      {
        key: 'profit',
        sortable: true,
        formatter: TableItemFormatter.currency,
      },
		];

		let pa = 0.33;
		let pb = 0;

		const salesDD = await this._loadDimensionByFilters(
			salesRef,
			'agents',
			pa,
			pb,
			this.includeTax ? 'amountWithTax' : 'amount',
		);

  pb += pa;
  pa = 0.33;

  const profitDD = await this._loadDimensionByFilters(
        salesRef,
        'agents',
        pa,
        pb,
        'profit',
    );

		pb += pa;
		pa = 0.33;

		const CNDD = await this._loadDimensionByFilters(
			cnRef,
			'agents',
			pa,
			pb,
			this.includeTax ? 'amountWithTax' : 'amount',
		);

		const result: Array<{ total: number; agent_name: string }> = salesDD.map(
			(dd, index) => {
				const item = {
					total: dd.sum - (this.includeCreditNote ? CNDD[index].sum : 0),
					agent_name: dd.text,
          profit: profitDD[profitDD.findIndex((a) => {
            return a.text === dd.text;
          })].sum,
				};

				return item;
			},
		);

		this.tableItems = result.filter((item) => item.total !== 0);

		this.saveHistory('tableItems', 'tableFields');
	}
}
