
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DailyNumberDisplayerFilterable from '@/components/reports-v2/components/filterables/DailyNumberDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {DashboardCashFlow, DashboardData, PermissionsGroup} from '@/store/models.def';

@Component({
	components: {
		DailyNumberDisplayerFilterable,
	},
})
export default class CashFlowDisplayer extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['dashboard'];
    }

    public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateAsOf'];

	public todayAmount = 0;

	public get expensiveHook() {
		const { selectedAsOfDate } = this;
		return JSON.stringify([selectedAsOfDate]);
	}

	public async expensiveCalc() {
		const mmtToday = moment(this.selectedAsOfDate);

		const ref = FilteredDatabase.ref('dashboardCashFlow').date(
			mmtToday.year(),
			mmtToday.month(),
			mmtToday.date(),
		);

		const refHead = await ref.get();
		const cards: DashboardCashFlow[] = await refHead.getCards();

		this.todayAmount = cards.reduce((a, b) => a + b.cashFlow, 0);

		this.saveHistory('todayAmount');
	}
}
