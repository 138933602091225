
import * as echarts from 'echarts/core';
import { addComma } from '@/util/number';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

export class LabelFormatter {
    public static pieChart = (param_s) => {
        const colorSpan = LabelFormatter._colorSpan;
        const params = param_s as echarts.EChartsCoreOption;
        let name;
        let value;
        const keys = Object.keys(params);
        const values = Object.values(params);

        if (keys && values) {
            keys.forEach((key, index) => {
                if (key === 'name') {
                    name = values[index];
                } else if (key === 'value') {
                    value = values[index];
                }
            });
        }
        return name + '\n' + gdbx.currencySymbol +
            ' ' + LabelFormatter.formatNumberIfDecimal(value);
    }
    public static pieChartWithoutCurrency = (param_s) => {
        const colorSpan = LabelFormatter._colorSpan;
        const params = param_s as echarts.EChartsCoreOption;
        let name;
        let value;
        const keys = Object.keys(params);
        const values = Object.values(params);

        if (keys && values) {
            keys.forEach((key, index) => {
                if (key === 'name') {
                    name = values[index];
                } else if (key === 'value') {
                    value = values[index];
                }
            });
        }
        return name + '\n' +
            ' ' + LabelFormatter.formatNumberIfDecimal(value);
    }
    public static pieChartWithoutCurrencyDecimal = (param_s) => {
        const colorSpan = LabelFormatter._colorSpan;
        const params = param_s as echarts.EChartsCoreOption;
        let name;
        let value;
        const keys = Object.keys(params);
        const values = Object.values(params);

        if (keys && values) {
            keys.forEach((key, index) => {
                if (key === 'name') {
                    name = values[index];
                } else if (key === 'value') {
                    value = values[index];
                }
            });
        }
        return name + '\n' + ' ' + value;
    }
    public static formatNumberIfDecimal(n: number, decimal: number = this._numDecimal()) {
        // if (n % 1 !== 0) {
        // 	return n.toFixed(decimal);
        // }
        // return n.toFixed(0);
        return addComma(n, decimal, true);
    }

    private static _colorSpan = (color) =>
        '<span style="display:inline-block;margin-right:5px;' +
        'border-radius:10px;width:9px;height:9px;background-color:' +
        color +
        '"></span>'

    private static _numDecimal() {
        return gdbx.numDecimal;
    }
}
