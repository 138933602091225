
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class TitleBar extends Vue {
	@Prop({ default: 'primary' }) public readonly variant!: string;
	@Prop({ default: '' }) public readonly title!: string;
	@Prop({ default: '' }) public readonly link!: string;

	public onClick() {
		if (!this.link) {
			return;
		}
		this.$router.push(this.link);
	}
}
